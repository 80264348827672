import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import OrderCard from './OrderCard';
import ImageButton24px from '../../components/ImageButton24px';
import './Header.scss';
import IconPlusCircleWhite24px from '../../../assets/Icon_Plus-Circle_White-24px.svg';
import IconSearch from '../../../assets/Icon_Search.svg';
import IconModalCrossBlack16px from '../../../assets/Icon_Modal-Cross_Black_16px.svg';
import IconDownload from '../../../assets/Icon_Download.svg';
import { validateAddCardRequest } from '../../../../../Networking';
import { withCloseOnEsc } from '../../../components/withCloseOnEsc';
import IconModalCross16px from "../../../assets/Icon_Modal-Cross_16px.svg";
import ContentContainer from '../../../components/ContentContainer';

const Label = React.memo(props => (
    <p className="cards-header-balance-label">
        <span className="cards-header-balance-label-text">{props.text}</span>
        <span className="cards-header-balance-label-value">{props.value}</span>
    </p>
));

class Header extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = { isSearchOpen: false, searchInputValue: "" };
        this.searchInput = React.createRef();
    }

    onSearchButtonClick = () => {
        this.setState({ isSearchOpen: !this.state.isSearchOpen }, () => {
            if (this.state.isSearchOpen && this.searchInput && this.searchInput.current) {
                this.searchInput.current.focus();
            }
        });
    }

    onSearchCloseButtonClick = () => {
        this.setState({ isSearchOpen: false });
    }

    onSearchFormSubmit = (e) => {
        e.preventDefault();
        this.props.onSearch(this.state.searchInputValue);
    }

    onSearchInputValueChange = (e) => {
        this.setState({ searchInputValue: e.target.value });
    }

    convertRange(oldMin, oldMax, oldVal, newMin, newMax) {
        const oldRange = oldMax - oldMin;
        if (oldRange === 0) {
            return 0;
        } else {
            return (((oldVal - oldMin) * (newMax - newMin)) / oldRange) + newMin;
        }
    }

    showOrderCard() {
        this.props.onModalOpen(<OrderCard {...{
            extending: false,
            stations: this.props.stations,
            onModalClose: this.props.onModalClose,
            cards: [{
                name: '',
                limit: '500',
                dayLimit: '0',
                selectedFuelType: 1,
                selectedProductType: 1,
                number: null
            }]
        }} />);
    }

    showErrorModal(message) {
        this.props.onModalOpen(
            <ErrorModal
                onCloseButtonClick={this.props.onModalClose}
                title={this.props.translation.errorModalTitle}
                message={message}
            />
        )
    }

    validateClientAddCard() {
        const accNr = this.props.login && this.props.login.currentRole && this.props.login.currentRole.accnr;
        if (!accNr) {
            return; //not in business role
        }
        this.props.validateAddCardRequest(
            {
                "action": "addCard",
                "accNr": accNr,
                "token": this.props.login.token,
            },
            data => {
                if (data.records.result) {
                    this.showOrderCard();
                } else {
                    this.showErrorModal(data.records.userError);
                }
            },
            error => {
            });
    }

    render() {
        let fill1 = 100;
        let fill2 = 0;
        let fill3 = 0;
        if (this.props.clientTotalLimit > 0) {
            fill2 = this.convertRange(0, this.props.clientTotalLimit, this.props.cardsTotalLimit, 0, 100);
            fill3 = this.convertRange(0, this.props.clientTotalLimit, this.props.selectedCardsUsedTotal, 0, 100);
        }
        return (
            <div className="cards-header">
                <h1 className="cards-header-title">{this.props.translation.title}</h1>
                <div className="cards-header-balance">
                    <div className="cards-header-balance-fills">
                        <div className="cards-header-balance-fill-1" style={{ width: `${fill1}%` }}></div>
                        <div className="cards-header-balance-fill-2" style={{ width: `${fill2}%` }}></div>
                        <div className="cards-header-balance-fill-3" style={{ width: `${fill3}%` }}></div>
                    </div>
                    <div className="cards-header-balance-labels">
                        <Label {...{ text: this.props.translation.selectedCards, value: ` ${this.props.selectedCardsUsedTotal} €` }} />
                        <Label {...{ text: this.props.translation.totalUsed, value: ` ${this.props.cardsTotalLimit || 0} €` }} />
                        <Label {...{ text: this.props.translation.businessLimit, value: ` ${this.props.clientTotalLimit} €` }} />
                    </div>
                </div>
                <div className={`cards-header-actions ${this.state.isSearchOpen ? "open" : ""}`}>
                    <ImageButton24px {...{ className: "cards_header-download_button", src: IconDownload, alt: "download", onClick: this.props.downloadExcel, tooltip: this.props.generalTranslation.downloadFile }} />
                    <div className="cards-header-spacer"></div>
                    <button className="new-card-button" onClick={() => this.validateClientAddCard()}>
                        <img className="new-card-button-img" src={IconPlusCircleWhite24px} alt="" />
                        <span className="new-card-button-text">{this.props.translation.newCardButton}</span>
                    </button>
                    <div className="cards-header-spacer"></div>
                    <div className="cards-header-search">
                        <button className="search-button" onClick={this.onSearchButtonClick}>
                            <img className="search-button_img" src={IconSearch} alt={this.state.isSearchOpen ? "close" : "open"} />
                        </button>
                        <form className="search-input-form" onSubmit={this.onSearchFormSubmit}>
                            <input className="search-input-field"
                                type="text"
                                size="1"
                                placeholder={this.props.translation.searchPlaceholder}
                                onChange={this.onSearchInputValueChange}
                                value={this.state.searchInputValue}
                                ref={this.searchInput} />
                        </form>
                        <button className="search-close-button" onClick={this.onSearchCloseButtonClick}>
                            <img className="search-close-button_img" src={IconModalCrossBlack16px} alt="close" />
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}
Header.propTypes = {
    clientTotalLimit: PropTypes.any,
    cardsTotalLimit: PropTypes.any,
    selectedCardsUsedTotal: PropTypes.any,
    onModalOpen: PropTypes.func,
    onModalClose: PropTypes.func,
    downloadExcel: PropTypes.func,
};

export default connect(
    state => ({
        translation: state.translation.cards.header,
        generalTranslation: state.translation.general,
        login: state.login
    }),
    dispatch => ({
        validateAddCardRequest: (body, success, error) => validateAddCardRequest(dispatch, body, success, error)
    }))(Header);

const ErrorModal = withCloseOnEsc(React.memo(props => (
    <ContentContainer {...{
        className: `order-card-modal error`,
        title: <h1 className="order-card-modal_title error">{props.title}</h1>,
        action:
            <button className="order-card-modal_close-button" onClick={props.onCloseButtonClick}>
                <img className="order-card-modal_close-button_img" src={IconModalCross16px} alt="close" />
            </button>,
        content:
            <div className="order-card-modal-content">
                <div className="order-card-modal-content_content">
                    <p className="modal-result" style={{ paddingLeft: '32px', marginBottom: '32px' }}>{props.message}</p>
                </div>
            </div>
    }} />
)));
