import React from 'react';
import { connect } from 'react-redux';
import ContentContainer from '../../../components/ContentContainer';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Loader from '../../../components/Loader';
import './Cards.scss';
import ChevronRight from '../../../assets/Chevron_Right.svg';

const Item = React.memo(props => {
    const percent = props.limit > 0 ? Math.floor(props.usedLimit / props.limit * 100) : 0;
    const flexStyle = `calc(${percent}% - 32px)`;
    return (
        <div className="cards-collection-item-outer">
            <div className={`cards-collection-item ${props.fade ? "fade" : ""}`}>
                <div className="cards-collection-item_text">
                    <span className="cards-collection-item_title">{props.name}</span>
                    <span className="cards-collection-item_number">{props.number}</span>
                </div>
                <div className="cards-collection-seek">
                    <div className="cards-collection-seek_fill" style={{ flexBasis: flexStyle }}></div>
                    <p className="cards-collection-seek_button">{percent}%</p>
                    <div className="cards-collection-seek_nofill"></div>
                </div>
            </div>
            {props.fade && <div className="cards-collection-item-fade"></div>}
        </div>
    );
});

const Collection = React.memo(props =>
    <div className="cards-collection">
        {props.items && props.items.map((item, index) => <Item {...{ ...item, key: index, fade: index === props.items.length - 1 }} />)}
    </div>
);

const Cards = React.memo(props =>
    <ContentContainer className={`cards ${props.className ? props.className : ""}`} {...{
        title: <h1 className="cards_title">{props.translation.title}</h1>,
        action: <button className="cards_show-all-button" onClick={props.navigateToCards}>
            <span className="cards_show-all-button_text">{props.translation.showAllButton}</span>
            <img className="cards_show-all-button_img" src={ChevronRight} alt="" />
        </button>,
        content:
            <div className="business-overview-cards-content">
                <TransitionGroup component={null}>
                    {props.isLoaderVisible && <CSSTransition key={"loader-fade"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                        <Loader {...{ className: "business-overview-cards-loader" }} />
                    </CSSTransition>}
                    {!props.isLoaderVisible && <CSSTransition key={"content-fade"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                        <Collection {...{ items: props.newestCards }} />
                    </CSSTransition>}
                </TransitionGroup>
            </div>
    }} />
);

export default connect(state => ({ translation: state.translation.overview.cards }))(Cards);