import 'whatwg-fetch'
import { languageChange } from './state/Login';
import { errorModal } from './state/Error';
import { loggedOut } from './state/Login';

function mustGetEnv(name) {
    const value = process.env[name];
    if (value === undefined || value === '') {
        throw new Error(`Missing required environment variable ${name}`);
    }
    return value;
}

const baseUrl = mustGetEnv('REACT_APP_API_URL');
const apiKey = mustGetEnv('REACT_APP_API_KEY');

export const url = `${baseUrl}?apiKey=${apiKey}`;
export const signUrls = {
    idCard: 'https://sign.olerex.ee/sign/idcard',
    mobileId: 'https://sign.olerex.ee/sign/mobileid',
    smartId: 'https://sign.olerex.ee/sign/smartid'
};


const request = (dispatch, url, body, successCallback, errorCallback, ignoreErrorModal = false, method = 'POST') => {
    window.fetch(url,
        {
            method,
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: method === 'GET' ? undefined : JSON.stringify(body)
        })
        .then(response => response.json())
        .then(data => {
            if (data && data.records && data.records.tokenExpired === 1) {
                dispatch(loggedOut());
            } else {
                if (data && data.records && data.records.statusCode !== undefined && data.records.statusCode !== 200) {
                    if (data.status && !(data.status.act === "olerexweb.business.cards" || data.status.act === "olerexweb.business.manage" || data.status.act === 'olerexweb.validate')) {
                        if (!ignoreErrorModal) {
                            dispatch(errorModal(true));
                        }
                        if (errorCallback) {
                            errorCallback(data);
                        }
                        return;
                    }
                }
                if (data && data.records && data.records.language) {
                    dispatch(languageChange(data.records.language));
                }
                if (successCallback) {
                    successCallback(data);
                }
            }
        })
        .catch(error => {
            if (!ignoreErrorModal) {
                dispatch(errorModal(true));
            }
            if (errorCallback) {
                errorCallback(error);
            } else {
                console.error('Unhandled request error', error);
            }
        });
};

export const authRequest = (dispatch, body, success, error) => request(dispatch, `${url}&act=olerexweb.auth`, body, success, error);
export const getActiveSessionsRequest = (dispatch, body, success, error) => request(dispatch, `${url}&act=olerexweb.getActiveSessions&token=${body.token}&userId=${body.userId}&accNr=${body.accNr}`, body, success, error, false, 'GET');

export const inviteRequest = (dispatch, body, success, error) => request(dispatch, `${url}&act=olerexweb.virtualFamilyInvite`, body, success, error);
export const updateUserData = (dispatch, body, success, error) => request(dispatch, `${url}&act=olerexweb.private.updateUserData`, body, success, error);

export const businessDashboardRequest = (dispatch, body, success, error) => request(dispatch, `${url}&act=olerexweb.business.dashboard`, body, success, error);
export const businessCardsRequest = (dispatch, body, success, error) => request(dispatch, `${url}&act=olerexweb.business.cards`, body, success, error);
export const validateAddCardRequest = (dispatch, body, success, error) => request(dispatch, `${url}&act=olerexweb.validate`, body, success, error);
export const businessInvoicesRequest = (dispatch, body, success, error) => request(dispatch, `${url}&act=olerexweb.business.invoices`, body, success, error);
export const businessBanklinksRequest = (dispatch, body, success, error) => request(dispatch, `${url}&act=olerexweb.business.banklink`, body, success, error);
export const businessTransactionsRequest = (dispatch, body, success, error) => request(dispatch, `${url}&act=olerexweb.business.transactions`, body, success, error);
export const businessManageRequest = (dispatch, body, success, error) => request(dispatch, `${url}&act=olerexweb.business.manage`, body, success, error);
export const businessVirtualFamilyRequest = (dispatch, body, success, error) => request(dispatch, `${url}&act=olerexweb.business.virtualfamily`, body, success, error);
export const businessWholeSaleRequest = (dispatch, body, success, error) => request(dispatch, `${url}&act=olerexweb.wholeSale`, body, success, error);

export const privateDashboardRequest = (dispatch, body, success, error) => request(dispatch, `${url}&act=olerexweb.private.dashboard`, body, success, error);
export const privateUpdateUserDataRequest = (dispatch, body, success, error) => request(dispatch, `${url}&act=olerexweb.private.updateUserData`, body, success, error, body.ignoreErrorModal);
export const privateTransactionsRequest = (dispatch, body, success, error) => request(dispatch, `${url}&act=olerexweb.private.transactions`, body, success, error);
export const privateVouchersRequest = (dispatch, body, success, error) => request(dispatch, `${url}&act=olerexweb.private.vouchers`, body, success, error);
export const privateInvoicesRequest = (dispatch, body, success, error) => request(dispatch, `${url}&act=olerexweb.business.invoices`, body, success, error); //business.invoices not private.invoices

export const privateElectricityRequest = (dispatch, body, success, error) => request(dispatch, `${url}&act=olerexweb.private.electricity`, body, success, error);

export const getTicketsRequest = (dispatch, personalCode, success, error) => request(dispatch, `${url}&act=olerexweb.travel.getTickets&personalCode=${personalCode}`, null, success, error);
