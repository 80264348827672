import React, {Component} from 'react';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import {Route} from "react-router-dom";
import {connect} from 'react-redux';
import {replace} from 'connected-react-router';

import { buildClassList } from '../../Utils';
import MobileFilters from './components/MobileFilters';
import NavBar from './navbar/NavBar';
import RoleChange from './modal/rolechange/RoleChange';
import LanguageChange from './modal/languagechange/LanguageChange';
import PrivateOverview from './person/overview/Overview';
import OlerexPlusModal from './person/overview/components/OlerexPlusModal';
import Vouchers from './person/vouchers/Vouchers';
import Transactions from './person/transactions/Transactions';
import IdCredit from './person/idcredit/IdCredit';
import JoinVirtualFamily from './modal/joinvirtualfamily/JoinVirtualFamily';
import './Dashboard.scss';
import {roleChange, loggedOut} from '../../state/Login';
import BusinessOverview from './business/overview/Overview';
import Cards from './business/cards/Cards';
import Invoicing from './business/invoicing/Invoicing';
import Manage from './business/manage/Manage';
import VirtualFamily from './business/virtualfamily/VirtualFamily';
import Electricity from './person/electricity/Electricity';
import ErrorModal from './modal/error/ErrorModal';
import {errorModal} from '../../state/Error';
import {getActiveSessionsRequest} from '../../Networking';
import LogoutModal from './modal/logout/LogoutModal';
import Wholesale from './wholesale/Wholesale';

if (!("scrollBehavior" in document.documentElement.style)) {
    require("scroll-behavior-polyfill");
}

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            yellowHeader: true,
            isDrawerOpen: false,
            isUserDropdownOpen: false,
            isRoleSelectOpen: false,
            isMobileFiltersOpen: false,
            mobileFiltersFilters: null,
            isOlerexPlusModalOpen: false,
            isJoinVirtualFamilyModalOpen: false,
            isWhiteModalOpen: false,
            isMobileDropdownOpen: false,
            sessions: [],
            isLogoutModalOpen: false
        };
    }

    onDrawerToggle = () => {
        this.onMobileDropdownClose();
        this.setState({isDrawerOpen: !this.state.isDrawerOpen}, () => {
            if (this.state.isDrawerOpen) { // disable scrolling when nav open as a drawer
                document.body.classList.add('no-scroll')
            } else {
                document.body.classList.remove('no-scroll');
            }
        });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.pathname !== this.props.pathname && this.state.isDrawerOpen) {
            this.onDrawerToggle();
        }
        if (prevProps.locationKey !== this.props.locationKey) {
            this.props.hideErrorModal();
        }
    }

    onUserDropdownToggle = () => {
        this.setState({isUserDropdownOpen: !this.state.isUserDropdownOpen}, () => this.modalDidOpen(this.state.isUserDropdownOpen));
    };

    onMobileDropdownToggle = (modalData) => {
        this.setState({
            isMobileDropdownOpen: !this.state.isMobileDropdownOpen,
            mobileDropdownData: modalData,
        }, () => this.modalDidOpen(this.state.isMobileDropdownOpen));
    };

    onUserDropdownOpen = (isUserDropdownOpen) => {
        this.setState({isUserDropdownOpen: isUserDropdownOpen}, () => this.modalDidOpen(this.state.isUserDropdownOpen));
    };

    onUserDropdownClose = () => {
        this.setState({isUserDropdownOpen: false}, () => this.modalDidOpen(this.state.isUserDropdownOpen));
    };

    onMobileDropdownClose = () => {
        this.setState({
            isMobileDropdownOpen: false,
            mobileDropdownData: null
        }, () => this.modalDidOpen(this.state.isMobileDropdownOpen));
    };

    // #region modals

    modalDidOpen = (isModalOpen) => {
        document.body.className = isModalOpen ? 'modal-open' : '';
    };

    onRoleChangeOpen = () => {
        this.setState({
            isRoleSelectOpen: !this.state.isRoleSelectOpen,
            isUserDropdownOpen: false
        }, () => this.modalDidOpen(this.state.isRoleSelectOpen));
    };

    onRoleChangeClose = () => {
        this.onDrawerToggle();
        this.setState({isRoleSelectOpen: false}, () => this.modalDidOpen(this.state.isRoleSelectOpen));
    };

    onLanguageChangeOpen = () => {
        this.setState({
            isLanguageChangeOpen: true,
            isUserDropdownOpen: false
        }, () => this.modalDidOpen(this.state.isLanguageChangeOpen));
    };

    onLanguageChangeClose = () => {
        this.setState({isLanguageChangeOpen: false}, () => this.modalDidOpen(this.state.isLanguageChangeOpen));
    };

    onLogoutModalClose = () => {
        this.setState({isLogoutModalOpen: false}, () => this.modalDidOpen(this.state.isLogoutModalOpen));
    };

    onModalOpen = (manageModalData) => {
        this.setState({
            isManageModalOpen: true,
            manageModalData: manageModalData
        }, () => this.modalDidOpen(this.state.isManageModalOpen));
    };
    onModalClose = () => {
        this.setState({
            isManageModalOpen: false,
            manageModalData: null
        }, () => this.modalDidOpen(this.state.isManageModalOpen));
    };

    onWhiteModalOpen = (modalData) => {
        this.setState({
            isWhiteModalOpen: true,
            manageModalData: modalData
        }, () => this.modalDidOpen(this.state.isWhiteModalOpen));
    };
    onWhiteModalClose = () => {
        this.setState({
            isWhiteModalOpen: false,
            manageModalData: null
        }, () => this.modalDidOpen(this.state.isWhiteModalOpen));
    };

    onSwitchDarkModal = (modalData) => {
        this.setState({isDarkModalOpen: false});
        setTimeout(function () { //Start the timer
            this.setState({
                isDarkModalOpen: true,
                manageModalData: modalData
            });
        }.bind(this), 600)
    };

    onDarkModalOpen = (modalData) => {
        this.setState({isWhiteModalOpen: false});
        setTimeout(function () { //Start the timer
            this.setState({
                isDarkModalOpen: true,
                manageModalData: modalData
            });
        }.bind(this), 600)
    };

    onDarkModalFastOpen = (modalData) => {
        this.setState({
            isDarkModalOpen: true,
            manageModalData: modalData
        });
    };

    onWhiteModalReOpen = (modalData) => {
        this.setState({isDarkModalOpen: false});
        setTimeout(function () { //Start the timer
            this.setState({
                isWhiteModalOpen: true,
                manageModalData: modalData
            });
        }.bind(this), 600)
    };

    onDarkModalClose = () => {
        this.setState({
            isDarkModalOpen: false,
            isWhiteModalOpen: false,
            manageModalData: null
        }, () => this.modalDidOpen(this.state.isDarkModalOpen));
    };

    onOlerexPlusModalOpen = (olerexPlusModalData) => {
        this.setState({
            isOlerexPlusModalOpen: true,
            olerexPlusModalData: olerexPlusModalData
        }, () => this.modalDidOpen(this.state.isOlerexPlusModalOpen));
    };

    onOlerexPlusModalClose = () => {
        this.setState({
            isOlerexPlusModalOpen: false,
            olerexPlusModalData: null
        }, () => this.modalDidOpen(this.state.isOlerexPlusModalOpen));
    };

    onMobileFiltersOpen = (filters) => {
        this.setState({
            isMobileFiltersOpen: true,
            mobileFiltersFilters: filters
        }, () => this.modalDidOpen(this.state.isMobileFiltersOpen));
    };

    onMobileFiltersClose = () => {
        this.setState({
            isMobileFiltersOpen: false,
            mobileFiltersFilters: null
        }, () => this.modalDidOpen(this.state.isMobileFiltersOpen));
    };

    onJoinVirtualFamilyModalOpen = () => {
        this.setState({isJoinVirtualFamilyModalOpen: true});
    };

    onJoinVirtualFamilyModalClose = () => {
        this.setState({isJoinVirtualFamilyModalOpen: false});
    };

    onLogout = () => {
        let userId = null;
        let accNr = null;

        if (this.props.login.currentRole) {
            userId = this.props.login.currentRole.userId;
            accNr = this.props.login.currentRole.accNr;
        }

        this.props.getActiveSessions({
            userId,
            accNr,
            token: this.props.login.token
        }, (res) => {
            if (res.records.statusCode !== 200) {
                this.props.loggedOut();

            } else if (res.records.sessions.length === 0) {
                this.props.loggedOut();
                if (window.webViewBridge) {
                    window.webViewBridge.send('logout');
                }

            } else {
                if (res.records.sessions.length === 1) {
                    this.onSessionEnd(res.records.sessions[0].apiCall);
                } else {
                    this.setState({sessions: res.records.sessions, isLogoutModalOpen: true});
                }
            }
        });
    }

    onSessionEnd = (apiCall) => {
        fetch(apiCall)
            .then(res => res.json())
            .then(data => {
                if (data.records.reload) {
                    this.props.loggedOut();
                    window.location.reload();
                }
                if (data.records.function === 'logoutApp') {
                    this.props.loggedOut();
                    if (window.webViewBridge) {
                        window.webViewBridge.send('logout');
                    }
                }
                this.onLogoutModalClose();
            });
    }

    render() {
        const isPrivate = this.props.login?.currentRole?.userId !== undefined;
        const classNames = buildClassList({
            private: isPrivate,
            business: !isPrivate,
            dashboard: true,
        });

        return (
            <div className={classNames}>
                <TransitionGroup component={null}>
                    {this.state.isUserDropdownOpen &&
                    <CSSTransition key={"page-content-fade-dropdown"} timeout={{enter: 600, exit: 600}}
                                   classNames={"fade"}>
                        <div className="modal-translucent" onClick={this.onUserDropdownClose}></div>
                    </CSSTransition>}
                    {this.state.isMobileFiltersOpen &&
                    <CSSTransition key={"page-content-fade-filters"} timeout={{enter: 600, exit: 600}}
                                   classNames={"fade"}>
                        <div className="modal-translucent" onClick={this.onMobileFiltersClose}></div>
                    </CSSTransition>}
                </TransitionGroup>
                <div className="header-placeholder"></div>
                <div className={`header ${this.props.location.pathname === "/" ? "" : "yellow-header"}`}>
                    <TransitionGroup component={null}>
                        {this.state.isUserDropdownOpen &&
                        <CSSTransition key={"header-fade-left"} timeout={{enter: 600, exit: 600}} classNames={"fade"}>
                            <div className="nav-user-dropdown-header-fade left"
                                 onClick={this.onUserDropdownToggle}></div>
                        </CSSTransition>}
                        {this.state.isUserDropdownOpen &&
                        <CSSTransition key={"header-fade-right"} timeout={{enter: 600, exit: 600}} classNames={"fade"}>
                            <div className="nav-user-dropdown-header-fade right"
                                 onClick={this.onUserDropdownToggle}></div>
                        </CSSTransition>}
                        {this.state.isMobileFiltersOpen &&
                        <CSSTransition key={"header-fade-dropdown"} timeout={{enter: 600, exit: 600}}
                                       classNames={"fade"}>
                            <div className="nav-user-dropdown-header-fade" onClick={this.onMobileFiltersClose}></div>
                        </CSSTransition> /*reuse*/}
                        {(this.state.isMobileDropdownOpen && this.props.isMobileLayout) &&
                        <CSSTransition key={"header-fade-dropdown"} timeout={{enter: 600, exit: 600}}
                                       classNames={"fade"}>
                            <div className="nav-user-dropdown-header-fade" onClick={this.onMobileDropdownClose}></div>
                        </CSSTransition>}
                    </TransitionGroup>
                    <NavBar {...{
                        onDrawerToggle: this.onDrawerToggle,
                        isDrawerOpen: this.state.isDrawerOpen,
                        onUserDropdownToggle: this.onUserDropdownToggle,
                        isUserDropdownOpen: this.state.isUserDropdownOpen,
                        onUserDropdownClose: this.state.onUserDropdownClose,
                        onRoleChangeOpen: this.onRoleChangeOpen,
                        onLanguageChangeOpen: this.onLanguageChangeOpen,
                        onLogoutButtonClick: this.onLogout,
                    }} />
                </div>
                <TransitionGroup component={null}>
                    {this.state.isRoleSelectOpen &&
                    <CSSTransition key={"role-change-modal"} timeout={{enter: 600, exit: 600}} classNames={"modal"}>
                        <div className="modal-translucent" onClick={this.onRoleChangeClose}>
                            <RoleChange {...{onCloseButtonClick: this.onRoleChangeClose}} />
                        </div>
                    </CSSTransition>}
                    {this.state.isLanguageChangeOpen &&
                    <CSSTransition key={"language-change-modal"} timeout={{enter: 600, exit: 600}} classNames={"modal"}>
                        <div className="modal-translucent" onClick={this.onLanguageChangeClose}>
                            <LanguageChange {...{onCloseButtonClick: this.onLanguageChangeClose}} />
                        </div>
                    </CSSTransition>}
                    {this.state.isLogoutModalOpen &&
                    <CSSTransition key={"language-change-modal"} timeout={{enter: 600, exit: 600}} classNames={"modal"}>
                        <div className="modal-translucent" onClick={this.onLogoutModalClose}>
                            <LogoutModal {...{
                                onCloseButtonClick: this.onLogoutModalClose,
                                sessions: this.state.sessions,
                                onSessionEnd: this.onSessionEnd
                            }} />
                        </div>
                    </CSSTransition>}
                    {this.state.isOlerexPlusModalOpen &&
                    <CSSTransition key={"olerex-plus-modal"} timeout={{enter: 600, exit: 600}} classNames={"modal"}>
                        <OlerexPlusModal {...this.state.olerexPlusModalData} />
                    </CSSTransition>}
                    {this.state.isManageModalOpen &&
                    <CSSTransition key={"manage-modal"} timeout={{enter: 600, exit: 600}} classNames={"modal"}>
                        <div className="modal-translucent" onClick={this.onModalClose}>
                            {this.state.manageModalData}
                        </div>
                    </CSSTransition>}
                    {this.state.isWhiteModalOpen &&
                    <CSSTransition key={"white-modal"} timeout={{enter: 600, exit: 600}} classNames={"modal"}>
                        <div className="modal-translucent modal-translucent-white" onClick={this.onWhiteModalClose}>
                            {this.state.manageModalData}
                        </div>
                    </CSSTransition>}
                    {this.state.isDarkModalOpen &&
                    <CSSTransition key={"dark-modal"} timeout={{enter: 600, exit: 600}} classNames={"modal"}>
                        <div className="modal-translucent modal-translucent-dark" onClick={this.onDarkModalClose}>
                            {this.state.manageModalData}
                        </div>
                    </CSSTransition>}
                    {this.state.isMobileFiltersOpen &&
                    <CSSTransition key={"mobile-filters"} timeout={{enter: 600, exit: 600}} classNames={"filters"}>
                        <MobileFilters {...{
                            onCloseButtonClick: this.onMobileFiltersClose,
                            filters: this.state.mobileFiltersFilters
                        }} />
                    </CSSTransition>}
                    {this.state.isJoinVirtualFamilyModalOpen &&
                    <CSSTransition key={"join-virtual-family-modal"} timeout={{enter: 600, exit: 600}}
                                   classNames={"modal"}>
                        <JoinVirtualFamily {...{onClose: this.onJoinVirtualFamilyModalClose}} />
                    </CSSTransition>}
                    {(this.state.isMobileDropdownOpen && this.props.isMobileLayout) &&
                    <CSSTransition key={"mobile-dropdown-fade"} timeout={{enter: 600, exit: 600}} classNames={"fade"}>
                        {this.state.mobileDropdownData}
                    </CSSTransition>}
                </TransitionGroup>

                {this.props.isErrorModalVisible ?
                    <ErrorModal onClose={() => {
                        this.props.navigateToDashboard();
                        this.props.hideErrorModal();
                    }}/> :
                    <>{isPrivate ?
                        <>
                            <Route exact path="/" render={() => <PrivateOverview {...{
                                onOlerexPlusModalOpen: this.onOlerexPlusModalOpen,
                                onOlerexPlusModalClose: this.onOlerexPlusModalClose,
                                onModalOpen: this.onModalOpen,
                                onModalClose: this.onModalClose
                            }} />}/>
                            <Route path="/vouchers" render={() => <Vouchers {...{
                                onModalOpen: this.onModalOpen,
                                onModalClose: this.onModalClose
                            }} />}/>
                            <Route path="/transactions" render={() =>
                                <Transactions {...{onMobileFiltersOpen: this.onMobileFiltersOpen}} />}/>
                            <Route path="/idcredit" render={() => <IdCredit {...{
                                onModalOpen: this.onModalOpen,
                                onModalClose: this.onModalClose,
                                onMobileFiltersOpen: this.onMobileFiltersOpen
                            }} />}/>
                            <Route path="/electricity" render={() => <Electricity {...{
                                onModalOpen: this.onWhiteModalOpen,
                                onModalClose: this.onWhiteModalClose,
                                onDarkModalOpen: this.onDarkModalOpen,
                                onDarkModalClose: this.onDarkModalClose,
                                onWhiteModalReOpen: this.onWhiteModalReOpen,
                                onSwitchDarkModal: this.onSwitchDarkModal,
                                onDarkModalFastOpen: this.onDarkModalFastOpen,
                                onMobileFiltersOpen: this.onMobileFiltersOpen,
                                onMobileDropdownToggle: this.onMobileDropdownToggle,
                                onMobileDropdownClose: this.onMobileDropdownClose,
                                isMobileDropdownOpen: this.state.isMobileDropdownOpen,
                                ...this.props
                            }} />}/>
                            <Route path="/wholesale" render={() => <Wholesale {...{
                                onModalOpen: this.onModalOpen,
                                onModalClose: this.onModalClose,
                                onMobileFiltersOpen: this.onMobileFiltersOpen,
                            }} />}/>
                        </> :
                        <>
                            <Route exact path="/" render={() => <BusinessOverview {...{
                                onOlerexPlusModalOpen: this.onOlerexPlusModalOpen,
                                onOlerexPlusModalClose: this.onOlerexPlusModalClose,
                                onModalOpen: this.onModalOpen,
                                onModalClose: this.onModalClose
                            }} />}/>
                            <Route path="/cards" render={() => <Cards {...{
                                onModalOpen: this.onModalOpen,
                                onModalClose: this.onModalClose,
                                onMobileFiltersOpen: this.onMobileFiltersOpen
                            }} />}/>
                            <Route path="/invoicing" render={() => <Invoicing {...{
                                onModalOpen: this.onModalOpen,
                                onModalClose: this.onModalClose,
                                onMobileFiltersOpen: this.onMobileFiltersOpen
                            }} />}/>
                            <Route path="/manage" render={() => <Manage {...{
                                onModalOpen: this.onModalOpen,
                                onModalClose: this.onModalClose
                            }} />}/>
                            <Route path="/virtualfamily/:id?" render={(props) => <VirtualFamily {...{
                                onModalOpen: this.onModalOpen,
                                onModalClose: this.onModalClose, ...props
                            }} />}/>
                            <Route path="/wholesale" render={() => <Wholesale {...{
                                onModalOpen: this.onModalOpen,
                                onModalClose: this.onModalClose,
                                onMobileFiltersOpen: this.onMobileFiltersOpen,
                            }} />}/>
                        </>}
                    </>
                }
            </div>
        );
    }
}

export default connect(
    state => ({
        login: state.login,
        pathname: state.router.location.pathname,
        locationKey: state.router.location.key,
        isErrorModalVisible: state.error.isErrorModalVisible,
        isMobileLayout: state.size.isMobileLayout,
    }),
    dispatch => ({
        roleChange: (role, name) => dispatch(roleChange(role, name)),
        loggedOut: () => dispatch(loggedOut()),
        hideErrorModal: () => dispatch(errorModal(false)),
        navigateToDashboard: () => dispatch(replace('/')),
        getActiveSessions: (body, success, error) => getActiveSessionsRequest(dispatch, body, success, error)
    }))(Dashboard);
